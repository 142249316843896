import React, { useEffect, useState } from 'react';
import Segment from '@components/segment';
import Layout from '@components/layout';
import Seo from '@components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const PAGE_STATE_IDLE = 'idle';
const PAGE_STATE_SUCCESS = 'success';

// TODO: Handle error state
const IndexPage = () => {
  const [pageState, setPageState] = useState(PAGE_STATE_IDLE);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (!params.has('crtoken')) {
      setPageState(PAGE_STATE_SUCCESS);
      return;
    }

    const crtoken = params.get('crtoken');

    fetch(`${process.env.GATSBY_API_URL_EU}patients/cap_reset_accept`, {
      method: 'PUT',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: crtoken }),
    })
      .then(() => {
        setPageState(PAGE_STATE_SUCCESS);
      })
      .catch(() => {
        setPageState(PAGE_STATE_SUCCESS);
      });
  }, []);

  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      dataTheme="white"
      HeaderFloatShow
      headerStyle="flex flex--center text-center container container--lg"
      headerContainerStyle="HeaderFloatContainer--position-relative"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />
      <Seo title="Bekräfta" language="sv" />

      <div className="margin-bottom--xxl">
        <div className="container container--sm margin-top--md">
          {pageState === PAGE_STATE_IDLE && (
            <div className="margin-top--sm flex flex--center-y text--center align--center alignItems flex--column">
              <p>Laddar...</p>
            </div>
          )}

          {pageState === PAGE_STATE_SUCCESS && (
            <div className="margin-top--sm flex flex--center-y text--center align--center alignItems flex--column">
              <div className="margin-bottom--sm">
                <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#60C286', width: 60, height: 60 }} />
              </div>

              <h1 className="margin-bottom--sm">
                Bekräftat
              </h1>

              <p>Nu kan du öppna Joint Academy appen och kontakta din fysioterapeut igen</p>

              <a href="https://web.eu.jointacademy.com/#/app/messages" className="btn btn--lg btn--primary margin-top--md">
                Öppna appen
              </a>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
